body {
  background-color: #282c34;
  font-family: 'Merriweather', serif;
}

h1, h2, h3 {
  font-family: 'Merriweather', serif;
}

.App {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
}

.App header {
  background-color: #f7eccf;
  height: 10vh;
  min-height: 50px;
  color: rgb(0, 0, 0);
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
}

main {
  padding: 10px;
  padding-top: 60px;  /* Added */
  padding-bottom: 60px;  /* Added */
  margin: 10vh 0;
  height: auto;
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 768px) {
  .center-text {
    font-size: 16px; /* smaller font size */
    margin-bottom: 50px; /* less space at the bottom */
  }
}

/* Tablet and Desktop-specific styles */
@media only screen and (min-width: 769px) {
  .center-text {
    font-size: 20px; /* original font size */
    margin-bottom: 200px; /* original space at the bottom */
  }
}
  
  p {
    /* Mobile-specific text size or spacing */
    line-height: 1.6;
  }
  
  main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #FFFCFB;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: #f2f2f7; /* Light gray background */
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center; /* Center vertically */
  padding: 0 10px; /* Add some padding */
}

form button {
  width: 40px; /* Set width and height to make it circular */
  height: 40px;
  background-color: #007aff; /* Blue color similar to iMessage */
  border: none;
  color: white;
  border-radius: 50%; /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px; /* Add some margin between input and button */
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: white; /* White background similar to iMessage */
  color: black; /* Black text color */
  outline: none;
  border: none;
  padding: 10px; /* Adjust padding */
  border-radius: 20px; /* Round the corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

button {
  background-color: #282c34; /* Green */
  font-family: 'Times New Roman', Times, serif;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  margin-top: 20px; /* adjusted */
  color: #282c34;
  background: #f7eccf;
  max-width: 400px;
  margin: 0 auto;
}

.center-text {
  max-width: 1000px;
  text-align: center;
  font-size: 20px;
  color: white;
  margin: 0 auto; /* center the element */
  margin-bottom: 200px; /* original margin-bottom */
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  margin-left: 0%c; /* Adjust this value to shift it to the left */
  color: rgb(11, 1, 1);
  position: relative;
  text-align: center;
  line-height: 2.0;
}

ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 30px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: rgb(11, 1, 1);
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}